import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ArrowBtn} from "../../app-constants";
import {Link} from "gatsby"
import axios from "axios";
import {baseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";
import {checkData} from "../../helper";


function Solutoinlisting(props) {
    const [servicesData, setServicesData] = useState([]);
    const getGiHubServiceWithAxios = async () => {
        const response = await axios.get(`${baseURL}/solutions-with-category`);
        setServicesData(response.data.data);
    };
    useEffect(() => {
        getGiHubServiceWithAxios();
    }, []);

    const data = servicesData;

    const [servicesData1, setServicesData1] = useState([]);
    const getGiHubServiceWithAxios1 = async () => {
        const response = await axios.get(`${baseURL}/industries-with-category`);
        setServicesData1(response.data.data);
    };
    useEffect(() => {
        getGiHubServiceWithAxios1();
    }, []);

    const data1 = servicesData1;

    return (
        <section className="p-100 pt-0">
            <Container>
                {data?.map((value, i) => (
                    <>
                        <Row>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="seriveces_listing page_listing">
                                <div className="example" data-text={`${value.name}`}>
                                    <h2>{value.name} Services</h2>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {value?.solutions?.data?.map((item, i) => (
                                <Col xs={12} md={6} lg={4} xl={4} xxl={4} key={i}>
                                    <div className="item_listing">
                                        <h4>{item.name}</h4>
                                        {HtmlParser(checkData(item, 'menu_txt'))}
                                        <Link to={`/solution/${item.slug}`}>
                                            {ArrowBtn}
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                ))}

                {data1?.map((value, i) => (
                    <>
                        <Row>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="seriveces_listing">
                                <div className="example" data-text={`${value.name}`}>
                                    <h2>{value.name} Services</h2>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {value?.industries?.data?.map((item, i) => (
                                <Col xs={12} md={6} lg={4} xl={4} xxl={4} key={i}>
                                    <div className="item_listing">
                                        <h4>{item.name}</h4>
                                        {HtmlParser(checkData(item, 'menu_txt'))}
                                        <Link to={`/industrie/${item.slug}`}>
                                            {ArrowBtn}
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                ))}
            </Container>
        </section>
    );
}

export default Solutoinlisting;