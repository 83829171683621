import React from 'react';
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import Banner from "../services-page/banner";
import Featured from "../home/featured";
import Partner from "../home/partner";
import Solutoinlisting from "./solutoinlisting";

function Index(props) {
    return (
        <Layout>
            <Seo title='TekRevol Services' description='TekRevol Services'/>
            <Breadcrumbs link1="TekRevol" link2="Solutions"/>
            <Banner title="Solutions"/>
            <Solutoinlisting/>
            <Featured/>
            <Partner/>
        </Layout>
    );
}

export default Index;